import React, { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import { SettingContext } from '../../../context/SettingsContext'
import DashboardLayout from '../DashboardConfigs/DashboardLayout'
import BluePrintListComponent from './BluePrintListComponent'

type Props = {}

export default function SampleProjectsPage({ }: Props) {

    return (
        <div>
            <DashboardLayout title='Blueprints'>
                <BluePrintListComponent />
            </DashboardLayout>
        </div>
    )
}