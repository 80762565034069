import { ConnectorModel, NodeConstraints, NodeModel } from "@syncfusion/ej2-react-diagrams";
import { ADXL345Ports, BOMotorPorts, BuzzerPorts, DCMotorPorts, dht11Ports, esp32Ports, IRPorts, KeypadPorts, LCDPorts, LDRPorts, LEDPorts, newShieldPorts, OLEDPorts, PushButtonPorts, RFIDPorts, RGB_LED_Ports, ServoPorts, shieldPorts, SmokeSensorPorts, TouchPorts, ultrasonicPorts } from "./Ports";



export const firmwares: NodeModel[] = [
    // {constraints:NodeConstraints.Default , id: "educobot-shield", width: 1811, height: 2236, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Sheild.svg", }, ports: shieldPorts },
    {constraints:NodeConstraints.Default  | NodeConstraints.HideThumbs, id: "eduCOBOT-Shield", width: 452, height: 559, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Sheild_with_ESP32.svg", }, ports: shieldPorts, tooltip:{
        content: 'eduCOBOT Shield with ESP32',
        relativeMode: 'Mouse',
    } },
    {constraints:NodeConstraints.Default  | NodeConstraints.HideThumbs, id: "New-eduCOBOT-Shield", width: 522, height: 559, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/New_Sheild.svg", }, ports: newShieldPorts, tooltip:{
        content: 'New eduCOBOT Shield with ESP32',
        relativeMode: 'Mouse',
    } },
    {constraints:NodeConstraints.Default , id: "esp32", width: 198, height: 371, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg", }, ports: esp32Ports, tooltip:{
        content: 'ESP32',
        relativeMode: 'Mouse',
    }  },
];

export const inputSensors: NodeModel[] = [
    {constraints:NodeConstraints.Default , id: "Push-Button", width: 37, height: 23, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Tactile+Switch.svg", }, ports: PushButtonPorts, tooltip:{
        content: 'Push Button',
        relativeMode: 'Mouse',
    }  },
    {constraints:NodeConstraints.Default ,rotateAngle:90, id: "IR-Sensor", width: 362 * 0.38, height: 633 * 0.38, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IR+Sensor.svg", }, ports: IRPorts, tooltip:{
        content: 'IR Sensor',
        relativeMode: 'Mouse',
    }   },
    {constraints:NodeConstraints.Default , id: "Touch-Sensor", width: 362 * 0.38, height: 513 * 0.38, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Touch+Sensor.svg", }, ports: TouchPorts, tooltip:{
        content: 'Touch Sensor',
        relativeMode: 'Mouse',
    }   },
    {constraints:NodeConstraints.Default ,rotateAngle:90, id: "DHT11-Sensor", width: 0.5 * 149, height: 0.5 * 374, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DHT11.svg", }, ports: dht11Ports, tooltip:{
        content: 'DHT11 Sensor',
        relativeMode: 'Mouse',
    }   },
    {constraints:NodeConstraints.Default , id: "LDR-Sensor", width: 0.335 * 812, height: 0.335 * 476, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LDR_PCB.svg", }, ports: LDRPorts, tooltip:{
        content: 'LDR Sensor',
        relativeMode: 'Mouse',
    }   },
    {constraints:NodeConstraints.Default , id: "MQ2-Sensor", width: 0.6 * 252, height: 0.6 * 142, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Smoke_Sensor.svg", }, ports: SmokeSensorPorts },
    {constraints:NodeConstraints.Default , id: "UltraSonic",rotateAngle:180, width: 0.6 * 529, height: 0.6 * 293, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Ultrasonic.svg", }, ports: ultrasonicPorts },
    {constraints:NodeConstraints.Default , id: "RFID", width: 0.5 * 383, height: 0.5 * 648, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RFID.svg", }, ports: RFIDPorts },
    {constraints:NodeConstraints.Default , id: "Keypad", rotateAngle:180,width: 373, height: 605, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Key_Pad.svg", }, ports: KeypadPorts },
]

export const outputSensors: NodeModel[] = [
    
    {constraints:NodeConstraints.Default , id: "LCD", width: 0.65 * 662, height: 0.65 * 265, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LCD.svg", }, ports: LCDPorts },
    {constraints:NodeConstraints.Default , id: "LED", width: 0.3 * 883, height: 0.3 * 623, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LED_PCB.svg", }, ports: LEDPorts },


    {constraints:NodeConstraints.Default , id: "DC-Motor", width: 0.3 * 523, height: 0.3 * 653, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DC+Motor.svg", }, ports: DCMotorPorts },
    {constraints:NodeConstraints.Default , id: "RGB-LED", width: 0.3 * 523, height: 0.3 * 673, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RGB_LED.svg", }, ports: RGB_LED_Ports },



    {constraints:NodeConstraints.Default , id: "Buzzer", width: 0.33 * 771, height: 0.33 * 578, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Buzzer_PCB.svg", }, ports: BuzzerPorts },
    {constraints:NodeConstraints.Default , id: "OLED", width: 0.6 * 257, height: 0.6 * 233, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/OLED.svg", }, ports: OLEDPorts },
    {constraints:NodeConstraints.Default , id: "Servo", width: 0.6* 335, height: 0.6* 250, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/SERVO_MOTOR.svg", }, ports: ServoPorts },
    // {constraints:NodeConstraints.Default , id: "8x8-Matrix", width: 50, height: 80, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32.png", } },
    {constraints:NodeConstraints.Default , id: "Accelerometer", width: 0.6*156, height: 0.6*114, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ADXL345.svg", }, ports:ADXL345Ports },
    {constraints:NodeConstraints.Default , id: "BO-Motor", width: 0.6*219, height: 0.6*617, offsetX: 300, offsetY: 100, shape: { type: "Image", source: "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/BO+Motor.svg", }, ports: BOMotorPorts },

]














//Initializes connector symbols for the symbol palette
export const connectorSymbols: ConnectorModel[] = [
    {
        id: "VCC-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        allowNodeOverlap:false,
        style: { strokeWidth: 5, strokeColor: 'red' },
        targetDecorator: { shape: "None" },
        connectionPadding: 10,
        connectorSpacing: 10,
        tooltip: {
            content: "VCC Pin",
        }
    },
    {
        id: "GND-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        style: { strokeWidth: 5, strokeColor: 'black' },
        targetDecorator: { shape: "None" },
        tooltip: {
            content: "GND Pin",
        }
    },
    {
        id: "Digital-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        style: { strokeWidth: 5, strokeColor: 'blue' },
        targetDecorator: { shape: "None" },
        tooltip: {
            content: "Digital Pin",
        }
    },
    {
        id: "Analog-Pin",
        type: "Orthogonal",
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 150, y: 150 },
        style: { strokeWidth: 5, strokeColor: 'gray' },
        targetDecorator: { shape: "None" },
        tooltip: {
            content: "Analog Pin",
            relativeMode: 'Mouse',
        }
    },
];




