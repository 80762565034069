import React from 'react';
import './App.css';
import './styles/index.scss';
import SettingContextProvider from './context/SettingsContext';
import UserContextProvider from './context/UserContext';
import Router from './routes';
import { HelmetProvider } from 'react-helmet-async';
import { StyledEngineProvider } from "@mui/material/styles";
import ConfiguredThemeProvider from "./theme/ThemeProvider";
import PreviewProvider from './context/PreviewContext';
import ConnectionProvider from './context/ConnectionContext';
import { registerLicense } from '@syncfusion/ej2-base';
import { ServicesConfigs } from './config.environment';
import LocationProvider from './context/LocationContext';
registerLicense(ServicesConfigs.syncfusion.KEY);

function App() {
    return (
        <HelmetProvider>
            <ConnectionProvider>
                <SettingContextProvider>
                    <UserContextProvider>
                        <LocationProvider>
                            <ConfiguredThemeProvider>
                                <StyledEngineProvider injectFirst>
                                    <PreviewProvider>
                                        <Router />
                                    </PreviewProvider>
                                </StyledEngineProvider>
                            </ConfiguredThemeProvider>
                        </LocationProvider>
                    </UserContextProvider>
                </SettingContextProvider>
            </ConnectionProvider>
        </HelmetProvider>
    );
}


export default App;