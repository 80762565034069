import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Card, MenuItem, Select, TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { useContext, useEffect, useState } from 'react';
import { ContentDialogBox } from '../../../../../components/dialog_box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CommonColorsSelectionComponent, { GetValueFromVariableName, getVariableValueFromVariableName, UpdateValueFromVariableName } from './Common';
import { ProjectDataType, WidgetDataType } from '../../../../../types/Dashboard';
import { BackendConfigs } from '../../../../../config.environment';
import { SettingContext } from '../../../../../context/SettingsContext';
import { APIResponse } from '../../../../../types/Response';
const label = { inputProps: { 'aria-label': 'Color switch demo' } };







const SwitchComponent = ({ deleteWidget, widget, colorValue, setWidgets, project, previewMode = false }: {
    widget: WidgetDataType,
    deleteWidget: () => void,
    colorValue?: string,
    widgets: WidgetDataType[],
    setWidgets: React.Dispatch<React.SetStateAction<WidgetDataType[]>>,
    project: ProjectDataType,
    previewMode: boolean,
    reRender?: () => void
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const { settings, handleGlobalLoading } = useContext(SettingContext)

    const [title, setTitle] = useState(widget.widgetTitleText);
    const [currentVariable, setCurrentVariable] = useState(widget.linkedVariable);
    const [switchValue, setSwitchValue] = useState(getVariableValueFromVariableName(widget.linkedVariable, project) == "1" ? true : false);


    async function updateDataContinuously() {
        if (!currentVariable) return
        const value = await GetValueFromVariableName(project.project._id, currentVariable);
        if (!!value) {
            setSwitchValue(value == "1" ? true : false);
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (settings.globalLoading.loading) return
            updateDataContinuously()
        }, 3000)
        return () => clearInterval(interval)
    }, [])

    const [color, setColor] = useState<"primary" | "secondary" | "error" | "info" | "success" | "warning" | "default" | string>(widget.widgetColor as string);
    useEffect(() => {
        setWidgets((preData) => {
            const newData = preData.map((widget_: WidgetDataType) => {
                if (widget_.widgetId === widget.widgetId) {
                    return {
                        ...widget_,
                        widgetTitleText: title,
                        linkedVariable: currentVariable,
                        widgetColor: color
                    }
                } else {
                    return widget_
                }
            })
            return newData
        })
    }, [color, currentVariable, title])





    async function syncChangesToVariable(value: boolean) {
        console.log(value, currentVariable);

        if (!currentVariable) return
        handleGlobalLoading(true)
        if (value) {
            await UpdateValueFromVariableName(project.project._id, currentVariable, "1")
        } else {
            await UpdateValueFromVariableName(project.project._id, currentVariable, "0")
        }
        handleGlobalLoading(false)
    }
    return (
        <>
            {/* <animated.div {...bindLogo()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "200px",
                touchAction: 'none',
                position: "relative"
            }} > */}
            <Card sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "10px",
                position: "relative",
                // margin: "10px",
                height: "98%",
                padding: "10px",
            }} elevation={2}>
                <div style={{
                    position: "relative",
                    zIndex: 9,
                    width: "100%"
                }}>
                    <div style={{
                        padding: "10px",
                    }}>
                        <p style={{
                            width: "100%",
                            fontSize: "12px",
                        }}>{title}</p>
                    </div>
                    {
                        !previewMode && <>
                            <DeleteIcon sx={{
                                position: "absolute",
                                top: 5,
                                right: 30,
                                fontSize: "15px",
                                transition: "all 0.5s",
                                ":hover": {
                                    scale: "1.5",
                                }
                            }} color='error' onClick={() => {
                                deleteWidget();
                            }} />
                            <EditIcon sx={{
                                position: "absolute",
                                top: 5,
                                right: 10,
                                fontSize: "15px",
                                transition: "all 0.5s",
                                ":hover": {
                                    scale: "1.5",
                                }
                            }} color='disabled' onClick={() => {
                                setIsEditing(true);
                            }} />
                        </>
                    }
                </div>

                <div style={{
                    padding: "10px",
                }}>
                    <Switch size='medium'  {...label} checked={switchValue} onChange={(event) => {
                        setSwitchValue(event.target.checked);
                        syncChangesToVariable(event.target.checked);
                    }} style={{
                        color: color,
                    }} />
                </div>
            </Card>
            {/* </animated.div> */}
            <ContentDialogBox
                isOpen={isEditing}
                onClose={() => {
                    setIsEditing(false);
                }}
                title='Switch Edit'
                maxWidth={"xs"}
                content={
                    <div>
                        <div style={{
                            padding: "10px",
                        }}>
                            <h6 style={{
                                textAlign: "center",
                            }}>Select Default Color for Widget</h6>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <CommonColorsSelectionComponent color={color} setColor={setColor} />
                            </div>

                            <TextField size='small' fullWidth label="Title" value={title} onChange={(e) => {
                                setTitle(e.target.value)
                            }} style={{
                                margin: "10px 0px",
                            }} />
                            <Select size='small' label="Linked Variable" fullWidth labelId="demo-simple-select-label" id="demo-simple-select" value={currentVariable} onChange={(e) => {
                                setCurrentVariable(e.target.value)
                            }}>
                                {project.project.variables.map((variable) => {
                                    return <MenuItem value={variable.name}>{variable.name}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>}

            />
        </>
    )
}
export default SwitchComponent