import { Button, TextField } from '@mui/material'
import React, { useContext } from 'react'
import { eduCOBOTColorScheme } from '../../../../context/SettingsContext'
import { ContentDialogBox } from '../../../../components/dialog_box'
import { ProjectDataType } from '../../../../types/Dashboard'
import AccessEditing from '../ProjectSettings/AccessEditing'
import { UserContext } from '../../../../context/UserContext'
import { formatDistance } from 'date-fns'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
type Props = {
    project: ProjectDataType,
    previewMode: boolean,
    refreshProject: () => void

}

function MonitoringHeader({
    project,
    previewMode,
    refreshProject
}: Props) {
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    return (
        <div style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 20px",
            width: "100%",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "100%"
            }}>

                <div style={{
                    maxWidth: "98%",
                }}>
                    <div style={{
                        margin: "10px",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }}>
                        <img src="https://cdn-icons-png.freepik.com/256/5956/5956592.png?semt=ais_hybrid" alt="" style={{
                            maxWidth: "30px",
                        }} />
                        <h3>
                            {
                                project.project.name
                            }
                            {
                                previewMode && <Button color='secondary' variant='outlined' size='small' style={{
                                    fontSize: "7px",
                                    textTransform: "none",
                                    margin: "5px",
                                    padding: "2px 2px",
                                }}>Application View</Button>
                            }
                        </h3>
                    </div>
                    {
                        previewMode && <div>
                            <p style={{
                                fontSize: "10px",
                                color: eduCOBOTColorScheme.black
                            }}>
                                Last updated At: {formatDistance(parseInt(project.project.lastUpdatedAt), Date.now(), { addSuffix: true })}
                            </p>
                            <p style={{
                                fontSize: "10px",
                                color: eduCOBOTColorScheme.gray
                            }}> {
                                    project.project.description
                                }
                            </p>
                        </div>
                    }
                </div>
            </div>
            {
                !previewMode &&
                <div style={{
                    maxWidth: "200px",
                }}>
                    <Button variant='contained' size='small' sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px",
                        zIndex: 99999999999
                    }} onClick={() => {
                        setIsControllerOpen(true);
                    }}>
                        Share
                    </Button>
                </div>

            }

            <ContentDialogBox
                isOpen={isControllerOpen}
                onClose={() => { setIsControllerOpen(false) }}
                content={
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "20px",
                    }}>

                        <AccessEditing setIsSettingsOpen={setIsControllerOpen} refreshProject={refreshProject} project={project.project} />
                            {
                                project.project.isPublic &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "30px",
                                }}>
                                    <TextField disabled size='small' fullWidth value={"https://iot.educobot.com/dashboard/project/" + project.project._id+"/monitoring-workspace-application"} label={"Public Link"} />
                                    <Button variant='contained' size='small' sx={{
                                        textTransform: "none",
                                    }} onClick={() => { navigator.clipboard.writeText("https://iot.educobot.com/dashboard/project/" + project.project._id+"/monitoring-workspace-application") }}>
                                        <ContentCopyIcon/>
                                    </Button>
                                </div>
                            }
                    </div>
                }
                title='Share Monitor'
                maxWidth="md"
            />

        </div>
    )
}

export default MonitoringHeader