import React, { useContext, useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { AppBar, Box, Button, Card, CardActions, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { UserContext } from '../../../context/UserContext';
import { BackendConfigs } from '../../../config.environment';
import { ProjectDataType } from '../../../types/Dashboard';
import { APIResponse } from '../../../types/Response';
import { ContentDialogBox } from '../../../components/dialog_box';
import ProjectDescription from '../Project/ProjectDescription';
import { toast } from 'react-toastify';

type Props = {}
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function BluePrintListComponent({ }: Props) {
    const theme = useTheme();

    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [bluePrintList, setBluePrintList] = React.useState<ProjectDataType[]>([])

    async function fetchProjects() {
        handleGlobalLoading(true, "Fetching Projects")
        setBluePrintList([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetAllBlueprintAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setBluePrintList(result.data)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        fetchProjects()
    }, [])

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexWrap: "wrap"
            }}>

                <Box sx={{ bgcolor: "transparent", width: "100%" }} >
                    <AppBar position="static" sx={{
                        background: "#EDF2FB",
                        color: "#000",
                    }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Activities" {...a11yProps(0)} style={{ textTransform: "none" }} />
                            <Tab label="Projects" {...a11yProps(1)} style={{ textTransform: "none" }} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0} dir={theme.direction} >
                        <div style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            flexWrap: "wrap"
                        }}>
                            {
                                bluePrintList.map((bluePrint) => {
                                    if (bluePrint.project.blueprintType === "Activity")
                                        return (
                                            <BlueprintCard title={bluePrint.project.name} image={bluePrint.project.blueprintImage} bluePrint={bluePrint} />
                                        )
                                })
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <div style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "start",
                            flexWrap: "wrap"
                        }}>
                            {
                                bluePrintList.map((bluePrint) => {
                                    if (bluePrint.project.blueprintType === "Project")
                                        return (
                                            <BlueprintCard title={bluePrint.project.name} image={bluePrint.project.blueprintImage} bluePrint={bluePrint} />
                                        )
                                })
                            }
                        </div>
                    </TabPanel>
                </Box>
            </div>
        </div>
    )
}



function BlueprintCard({ image, title, bluePrint }: { image: string, title: string, bluePrint: ProjectDataType }) {
    const navigate = useNavigate()
    const [isProjectDetailsOpen, setIsProjectDetailsOpen] = React.useState(false)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const { user } = useContext(UserContext)
    async function makeBluePrintAsProject() {
        handleGlobalLoading(true, "Creating Project")
        try {
            const response = await fetch(`${BackendConfigs.url}/MakeThisBluePrintAsProjectAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: bluePrint.project._id,
                    ownerId: user.userData?.id,
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
                navigate(routes.DASHBOARD.PROJECTS)
            }else{
                toast.error(result.message)

            }
        } catch (error) {
            console.log(error);
            
        }
        handleGlobalLoading(false)
    }



    return (

        <Card style={{
            width: "min(300px, 100%)",
            minHeight: "min(200px, 500px)",
            // maxHeight: "max(200px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "20px",
            margin: "10px",
            background: "transparent",
            backdropFilter: "blur(2px)",
            paddingBottom: "50px",
        }}
            sx={{
                ":hover": {
                    scale: "1.1",
                }
            }}
        >

            <img src={image} alt="" style={{
                // minWidth: "150px",
                // width: "100%",
                maxWidth: image === "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png" ? "100px" : "300px",
                maxHeight: image === "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png" ? "100px" : "160px",
            }} />
            <CardActions style={{
                width: "100%",
                background: "#DDDDDD",
                textAlign: "center",
                position: "absolute",
                bottom: "0px",
                padding: "15px",
                height: "50px",
            }}
                onClick={() => {
                    setIsProjectDetailsOpen(true)
                }}
            >
                <h5 style={{
                    width: "100%",
                    color: eduCOBOTColorScheme.gray,
                    textAlign: "center",
                }} >
                    {title}
                </h5>
            </CardActions>
            <ContentDialogBox
                isOpen={isProjectDetailsOpen}
                onClose={() => setIsProjectDetailsOpen(false)}
                title={"Blueprint: " + title}
                actions={<>
                    <Button variant={"contained"} sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px",
                    }} onClick={makeBluePrintAsProject}>
                        Use This BluePrint for Project
                    </Button>

                </>}
                content={<>
                    <div style={{
                        width: "99%",
                    }}>

                        <ProjectDescription project={bluePrint} isBluePrintDescription={true} />
                    </div>
                </>}
            />
        </Card>
    )
}

export default BluePrintListComponent