import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Card, MenuItem, Select, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { ContentDialogBox } from '../../../../../components/dialog_box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CommonColorsSelectionComponent from './Common';
import Chart from 'react-apexcharts';
import { color } from '../../../../../theme/index';
import { ProjectDataType, WidgetDataType } from '../../../../../types/Dashboard';

const CommentComponent = ({ deleteWidget, widget, colorValue, setWidgets, project, previewMode = false }: {
    widget: WidgetDataType,
    deleteWidget: () => void,
    colorValue?: string,
    widgets: WidgetDataType[],
    setWidgets: React.Dispatch<React.SetStateAction<WidgetDataType[]>>,
    project: ProjectDataType,
    previewMode: boolean
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(widget.widgetTitleText);
    const [currentVariable, setCurrentVariable] = useState(widget.linkedVariable);
    const [color, setColor] = useState<"primary" | "secondary" | "error" | "info" | "success" | "warning" | "default" | string>(widget.widgetColor as string);
    useEffect(() => {
        setWidgets((preData) => {
            const newData = preData.map((widget_: WidgetDataType) => {
                if (widget_.widgetId === widget.widgetId) {
                    return {
                        ...widget_,
                        widgetTitleText: title,
                        linkedVariable: currentVariable,
                        widgetColor: color
                    }
                } else {
                    return widget_
                }
            })
            return newData
        })
    }, [color, currentVariable, title])
    return (
        <>
            {/* <animated.div {...bindLogo()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "300px",
                touchAction: 'none',
                position: "relative",
                width: "100%"
            }} > */}
            <Card sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "10px",
                position: "relative",
                // margin: "10px",
                height: "98%",
                padding: "10px",
            }} elevation={2}>
                <div style={{
                    position: "relative",
                    zIndex: 9,
                    width: "100%"
                }}>
                    {
                        !previewMode && <>
                            <DeleteIcon sx={{
                                position: "absolute",
                                top: 5,
                                right: 30,
                                fontSize: "15px",
                                transition: "all 0.5s",
                                ":hover": {
                                    scale: "1.5",
                                }
                            }} color='error' onClick={() => {
                                deleteWidget();
                            }} />
                            <EditIcon sx={{
                                position: "absolute",
                                top: 5,
                                right: 10,
                                fontSize: "15px",
                                transition: "all 0.5s",
                                ":hover": {
                                    scale: "1.5",
                                }
                            }} color='disabled' onClick={() => {
                                setIsEditing(true);
                            }} />
                        </>
                    }
                </div>

                <div style={{
                    padding: "10px",
                }}>

                    <Typography component={'h5'} style={{
                        fontSize: "13px",
                        color: color,

                    }}>
                        <pre>
                            {title}
                        </pre>
                    </Typography>
                </div>
            </Card>
            {/* </animated.div> */}
            <ContentDialogBox
                isOpen={isEditing}
                onClose={() => {
                    setIsEditing(false);
                }}
                title='Label Edit'
                maxWidth={"xs"}
                content={
                    <div>
                        <div style={{
                            padding: "10px",
                        }}>
                            <h6 style={{
                                textAlign: "center",
                            }}>Select Default Color for Widget</h6>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <CommonColorsSelectionComponent color={color} setColor={setColor} />
                            </div>

                            <TextField size='small' fullWidth label="Comment" value={title} onChange={(e) => {
                                setTitle(e.target.value)
                            }} style={{
                                margin: "10px 0px",
                            }} />
                        </div>
                    </div>}
            />
        </>
    )
}
export default CommentComponent