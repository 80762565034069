import Blockly from "../../../../components/micropython-block-editor/configs/micropython-blocks"
import { CircuitJSONType } from "../../../../types/Editor"

export const CircuitIDToBlocksMapper: { [key: string]: string[] } = {
    "eduCOBOT-Shield": [],
    "New-eduCOBOT-Shield": [],
    "esp32": [],
    "Push-Button": [],
    "Touch-Sensor": [
        "touch_sensor_input"
    ],
    "DC-Motor": [
        "set_motor"
    ],
    "RGB-LED": [
        "traffic_light_upgraded"
    ],
    "IR-Sensor": [
        "ir_sensor_input"
    ],
    "LDR-Sensor": [
        "light_sensor_input"
    ],
    "DHT11-Sensor": [
        "define_dht_pin",
        "read_humidity",
        "read_temp_c",
        "read_temp_f"
    ],
    "MQ2-Sensor": [],
    "UltraSonic": [
        "define_ultrasonic_pins",
        "dist_cm",

    ],
    "RFID": [
        "init_rfid",

    ],
    "Keypad": [
        "initialize_keypad",
        "keypad_col_pins",
        "keypad_row_pins",
        "readkey",
        "key_press_detected_in"
    ],
    "LCD": [
        "init_i2c_lcd",
        "i2c_clear_lcd_display",
        "i2c_lcd_print"
    ],
    "LED": [
        "set_led"
    ],
    "Buzzer": [
        "set_buzzer"
    ],
    "OLED": [],
    "Servo": [
        "servo"
    ],
    "Accelerometer": [
    ],
    "BO-Motor": [
        "set_bo_motor"
    ],
}



export function getSensorsList(circuitJSON: string) {
    const circuit = JSON.parse(circuitJSON) as CircuitJSONType
    const sensors = [] as string[]

    circuit.nodes.forEach((node) => {
        // console.log(node.id);
        if(Object.keys(CircuitIDToBlocksMapper).includes(node.id.slice(0, -5))){
            sensors.push(node.id.slice(0, -5))
        }else{
            sensors.push(node.id.slice(0, -10))
        }
    })
    return sensors
}


export function AddBlocksToWorkspaceByCircuit(circuitJSON: string) {
    Blockly.getMainWorkspace().clear()
    console.log(circuitJSON);
    const circuit = JSON.parse(circuitJSON) as CircuitJSONType
    console.log(circuit.nodes);
    const sensors = [] as string[]
    circuit.nodes.forEach((node) => {
        console.log(node.id.slice(0, -5));
        sensors.push(node.id.slice(0, -5))
    })
    sensors.forEach(element => {
        const value = CircuitIDToBlocksMapper[element]
        value.forEach(element2 => {
            const newelement = Blockly.getMainWorkspace().newBlock(element2)
            newelement.setPreviousStatement(true)
            Blockly.getMainWorkspace().setBlockById(element2, newelement)
        })
    });
    // Blockly.getMainWorkspace().clear()
    // newBlock.initModel()
    // newBlock.rendered = true
    const xml = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
    // Blockly.getMainWorkspace().setBlockById("define_ultrasonic_pins",newBlock)
    // console.log(newBlock.rendered);
    return xml

}
