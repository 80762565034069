import { Button, Card, Container } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes/routes'

type Props = {

}

function NotFound({ }: Props) {
  const navigate = useNavigate()
  return (
    <div>
      <Container style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Card style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          width: "min(900px, 90%)",
        }}>
          <img src="https://cdn.dribbble.com/users/1138875/screenshots/4669703/404_animation.gif" style={{
            maxWidth: "max(300px,90%)",
          }} alt="" />
          <Button onClick={()=>{
            navigate(routes.DASHBOARD.ROOT)
          }} variant='outlined' color='warning'>Go To Dashboard</Button>
        </Card>
      </Container>
    </div>
  )
}

export default NotFound