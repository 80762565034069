import React, { useContext, useEffect, useState } from 'react'
import CircuitryHeader from './CircuitryHeader'
import CircuitDesigner from './CircuitDesigner';
import CircuitryFooter from './CircuitryFooter';
import { UserContext } from '../../../../context/UserContext';
import { SettingContext } from '../../../../context/SettingsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectDataType, Variable } from '../../../../types/Dashboard';
import { BackendConfigs } from '../../../../config.environment';
import { toast } from 'react-toastify';
import { APIResponse } from '../../../../types/Response';
import CodeGenerator from './CodeGenerator';
import { Button, Card, Container, Grid, TextField, Tooltip, Typography } from '@mui/material';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { AlertDialogBox, ContentDialogBox } from '../../../../components/dialog_box';
import DeleteIcon from '@mui/icons-material/Delete';
import { routes } from '../../../../routes/routes';
import { AddBlocksToWorkspaceByCircuit, getSensorsList } from './CircuitToBlockMapper';
import index from '../../../../../../IOT-Server/APIs/Defaults';
import MonitorIcon from '@mui/icons-material/Monitor';
type Props = {}


function getLastUsedScreen() {
    let isCircuitDesignerOpen = localStorage.getItem("isCircuitDesignerOpen")
    if (isCircuitDesignerOpen) {
        if (isCircuitDesignerOpen === "true") {
            return true
        } else {
            return false
        }
    }
    return false
}


function CircuitryWorkspacePage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [xml, setXml] = useState<string>("")
    const [code, setCode] = useState<string>("")
    const [circuitJSON, setCircuitJSON] = useState<string>("")
    const [isCircuitDesignerOpen, setIsCircuitDesignerOpen] = useState<boolean>(getLastUsedScreen())
    const [isSavingTab, setIsSavingTab] = useState<boolean>(false)
    const [variables_list, setVariables_list] = useState<Variable[]>([])
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshWorkspace, setRefreshWorkspace] = useState<boolean>(false)
    const [keys, setKeys] = useState<{
        [key: string]: string
    }>({

    })
    useEffect(() => {
        localStorage.setItem("isCircuitDesignerOpen", String(isCircuitDesignerOpen))
    }, [isCircuitDesignerOpen])
    const [isAPIKeyDialogOpen, setIsAPIKeyDialogOpen] = useState<boolean>(false)
    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Fetching Project")
            // setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    if (result.success && result.data.project) {

                        console.log(result.data);
                        setProjectData(result.data)
                        setCode((result.data as ProjectDataType).project.microPythonCode)
                        setXml((result.data as ProjectDataType).project.microPythonXML)
                        setCircuitJSON((result.data as ProjectDataType).circuit.circuitJSON)
                        setVariables_list((result.data as ProjectDataType).project.variables)
                        setKeys((preKeys) => {
                            return {
                                ...preKeys,
                                secretKey: (result.data as ProjectDataType).project._id
                            }
                        })
                    } else {
                        navigate(routes.DASHBOARD.ROOT)
                    }
                } else {
                    navigate(routes.DASHBOARD.ROOT)
                }
            } catch (error) {
                console.log(error);
                toast.error("Error Fetching Project")
            }
            handleGlobalLoading(false)
        }
    }
    async function getAPIKey() {
        if (!user.userData?.id) return
        try {
            const response = await fetch(`${BackendConfigs.url}/GetAPIKeyAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                console.log(result);

                if (result.success) {
                    setKeys((preKeys) => {
                        return {
                            ...preKeys,
                            apiKey: result.data.apiKey,
                        }
                    })
                    return
                } else {
                    toast.error(result.message)
                    setIsAPIKeyDialogOpen(true)
                }
            }
            else {
                setIsAPIKeyDialogOpen(true)
            }
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        getProject()
    }, [user, projectId])
    useEffect(() => {
        getAPIKey()
    }, [user])

    async function saveCircuit(circuitJSON: string) {
        handleGlobalLoading(true, "Saving Circuit")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeCircuitJsonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    circuitJSON,
                    sensorsList: getSensorsList(circuitJSON)
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving circuit")
        }
        handleGlobalLoading(false)
    }

    async function saveCode() {
        handleGlobalLoading(true, "Saving Code")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeMicroPythonCodeAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    code
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving code")
        }
        handleGlobalLoading(false)
    }

    async function saveBlocksCode() {
        handleGlobalLoading(true, "Saving Code")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeMicroPythonXMLAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    xml
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving Blocks")
        }
        handleGlobalLoading(false)
    }


    async function saveVariables() {
        await saveBlocksCode()
        handleGlobalLoading(true, "Saving Variables")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeVariablesAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    variables: variables_list.filter((variable) => variable.name !== "")
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
                getProject()
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving variables")
        }
        handleGlobalLoading(false)
    }
    async function saveAll() {
        handleGlobalLoading(true, "Saving All Stuffs")
        await saveCircuit(circuitJSON)
        await saveCode()
        await saveVariables()
        handleGlobalLoading(false)
    }
    // useEffect(() => {
    //     console.log(circuitJSON);
    // }, [circuitJSON])

    return (
        <div>

            {
                projectData ? <>
                    {/* <CircuitryHeader project={projectData} code={code} setCode={setCode} xml={xml} setXml={setXml} isCircuitDesignerOpen={isCircuitDesignerOpen} setIsCircuitDesignerOpen={setIsCircuitDesignerOpen} /> */}
                    <div key={refreshData ? "1" : "2"}>
                        {
                            isCircuitDesignerOpen ?
                                <CircuitDesigner project={projectData} circuitJSON={circuitJSON} setCircuitJSON={setCircuitJSON} /> :
                                <CodeGenerator key={refreshWorkspace ? "1" : "2"} project={projectData} code={code} setCode={setCode} xml={xml} setXml={setXml} setIsCircuitDesignerOpen={setIsCircuitDesignerOpen} circuitJSON={circuitJSON} keys={keys} />
                        }
                        {/* <CircuitryFooter setIsSavingTab={setIsSavingTab} /> */}
                    </div>

                </> : <></>
            }

            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', height: "100vh", position: "fixed", top: "0", right: 0 }}>
                <Typography style={{
                    backgroundColor: "white",
                    minHeight: "10vh",
                    border: "0.5px solid #00000014",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Tooltip title={isCircuitDesignerOpen ? "Close Circuit Designer" : "Open Code Editor"} placement='left'>
                        <Button style={{}} onClick={() => {
                            setIsCircuitDesignerOpen(!isCircuitDesignerOpen)
                        }}>
                            <img src={isCircuitDesignerOpen ? "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IDE.svg" : "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Circuit.svg"} width={30} alt="" />
                        </Button>
                    </Tooltip>
                    <Tooltip title={isSavingTab ? "Close Save Tab" : "Open Save Tab"} placement='left'>
                        <Button style={{}} onClick={() => {
                            setIsSavingTab(!isSavingTab)
                        }}>
                            <BeenhereIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Update Code as per the circuit"} placement='left'>
                        <Button disabled={isCircuitDesignerOpen} style={{}} onClick={() => {
                            handleGlobalLoading(true, "Updating Code")
                            AddBlocksToWorkspaceByCircuit(circuitJSON)
                            setTimeout(() => {
                                handleGlobalLoading(false)
                                setRefreshWorkspace(!refreshWorkspace)
                            }, 1000)
                        }}>
                            <TrackChangesIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Reset to last saved code"} placement='left'>
                        <Button style={{}} onClick={() => {
                            setCode(projectData?.project.microPythonCode ?? "")
                            setXml(projectData?.project.microPythonXML ?? "")
                            setCircuitJSON(projectData?.circuit.circuitJSON ?? "")
                            setRefreshData(!refreshData)
                        }}>
                            <RotateLeftIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Monitor"} placement='left'>
                        <Button style={{}} onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.MONITORING_WORKSPACE(projectData?.project._id as string))
                        }}>
                            <MonitorIcon />
                        </Button>
                    </Tooltip>
                </Typography>
            </div>
            <ContentDialogBox
                isOpen={isSavingTab}
                onClose={() => setIsSavingTab(false)}
                title='Save Changes'
                content={<>
                    <Container sx={{
                        padding: "10px",
                    }}>

                        <Grid columns={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }} container spacing={2}>
                            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                <h6 style={{ marginBottom: "10px" }}>Last Saved IOT-Variable List</h6>
                                {
                                    projectData?.project.variables.length === 0 && <Card sx={{
                                        padding: "10px",
                                        minHeight: "100px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <h6>No Variables Found</h6>
                                    </Card>
                                }
                                {
                                    projectData?.project.variables.map((variable: Variable, index: number) => (
                                        <TextField
                                            key={index}
                                            fullWidth
                                            id="outlined-multiline-flexible"
                                            label="Code"
                                            multiline
                                            maxRows={10}
                                            value={variable.name}
                                            size="small"
                                            disabled
                                            sx={{
                                                margin: "5px 0",
                                                fontSize: "10px"
                                            }}
                                        />
                                    ))
                                }
                            </Grid>
                            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                <h6 style={{ marginBottom: "10px" }}>New IOT-variable list</h6>
                                {
                                    variables_list.map((variable, index) => (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="outlined-multiline-flexible"
                                                label="Variable Name"
                                                multiline
                                                maxRows={10}
                                                value={variable.name}
                                                onChange={(e) => {
                                                    let new_variables_list = [...variables_list]
                                                    new_variables_list[index].name = e.target.value
                                                    setVariables_list(new_variables_list)
                                                }}
                                                sx={{
                                                    fontSize: "10px",
                                                    marginBottom: "10px"
                                                }}
                                            />
                                            <Button onClick={() => {
                                                console.log(xml);
                                                if (xml.includes(`<block type="${variable.name}"`)) {
                                                    toast.error("Variable is used in code, please remove from there to update it")
                                                } else {
                                                    setVariables_list(variables_list.filter((v, i) => i !== index))
                                                }
                                            }}>
                                                <DeleteIcon />
                                            </Button>
                                        </div>
                                    ))
                                }
                                <div>
                                    <Button variant='outlined' color='success' sx={{
                                        textTransform: "none",
                                        borderRadius: "6px",
                                        fontWeight: "bolder",
                                        fontSize: "10px",
                                        width: "100%",
                                    }} onClick={() => {
                                        setVariables_list([...variables_list, { name: "", value: "", type: "string" }])
                                    }}>
                                        Add Variable
                                    </Button>
                                </div>
                                {/* <TextField
                                    fullWidth
                                    id="outlined-multiline-flexible"
                                    label="Code"
                                    multiline
                                    maxRows={10}
                                    value={code}
                                    onChange={(e) => {
                                        setCode(e.target.value)
                                    }}
                                /> */}
                            </Grid>

                        </Grid>
                    </Container>

                </>}
                actions={<>
                    <Button
                        disabled={!isCircuitDesignerOpen}

                        variant='contained'
                        size='small'
                        onClick={() => {
                            saveCircuit(circuitJSON)
                            setIsSavingTab(false)
                        }}
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px"
                        }}
                    >
                        Save Circuit
                    </Button>
                    <Button
                        disabled={isCircuitDesignerOpen}
                        variant='contained'
                        size='small'
                        onClick={() => {
                            saveCode()
                            saveBlocksCode()
                            setIsSavingTab(false)
                        }}
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px"
                        }}
                    >
                        Save Code
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            saveVariables()
                            setIsSavingTab(false)

                        }}
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px"
                        }}
                    >
                        Save Variables
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            saveAll()
                            setIsSavingTab(false)
                        }}
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px"
                        }}
                    >
                        Save All
                    </Button>
                </>}
            />
            <AlertDialogBox
                isOpen={isAPIKeyDialogOpen}
                onClose={() => setIsAPIKeyDialogOpen(false)}
                title='API Key'
                message='API key not found, please create'
                successAction={
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            setIsAPIKeyDialogOpen(false)
                            navigate(routes.DASHBOARD.SETTINGS)
                        }}
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            padding: "5px 30px",
                        }}
                    >
                        Create
                    </Button>
                }

            />
        </div>
    )
}

export default CircuitryWorkspacePage