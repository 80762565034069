import MonitoringHeader from './MonitoringHeader'
import MonitorDesigner from './MonitorDesigner'
import MonitoringFooter from './MonitoringFooter'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../context/UserContext'
import { SettingContext } from '../../../../context/SettingsContext'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectDataType, WidgetDataType } from '../../../../types/Dashboard'
import { BackendConfigs } from '../../../../config.environment'
import { APIResponse } from '../../../../types/Response'
import { Button, Card, Container, Tooltip, Typography } from '@mui/material'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MonitorIcon from '@mui/icons-material/Monitor';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import Preview from './Preview'
import { motion } from 'framer-motion';
import { framerAnimation } from '../../../../theme/animation/MotionConfigs'
import { toast } from 'react-toastify'
import { routes } from '../../../../routes/routes'

type Props = {}

function MonitoringWorkspacePage({ }: Props) {
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [widgets, setWidgets] = useState<WidgetDataType[]>([])
    const [previewMode, setPreviewMode] = useState(true)
    const [reRender, setRerender] = useState(false)
    const [isShared, setIsShared] = useState(true)

    async function getProject() {
        if (!!projectId) {
            handleGlobalLoading(true, "Loading Project")
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    if(!result.success){
                        handleGlobalLoading(false)
                        navigate(routes.NOTFOUND)
                        return
                    }
                    console.log(result.data.project.isPublic);
                    setIsShared(result.data.project.isPublic)
                    setProjectData(result.data)
                    setWidgets((result.data as ProjectDataType).monitor.widgets)
                }
            } catch (error) {
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()

    }, [projectId])
    function deleteWidget(widgetId: string) {

    }
    return (
        <div style={{
            height: "100dvh",
            display: 'flex',
            // border: "1px solid gray",
            // justifyContent: "center",
            flexDirection: "column"
        }}>
            {
                isShared ? <>

                    {
                        projectData && <MonitoringHeader refreshProject={getProject} project={projectData} previewMode={previewMode} />
                    }

                    <motion.div
                        initial={framerAnimation.left2right.initial}
                        animate={framerAnimation.left2right.animate}
                        exit={framerAnimation.left2right.exit}
                        transition={framerAnimation.left2right.transition}
                        style={{
                            height: "100%",
                        }}
                    >
                        {
                            projectData && <MonitorDesigner key={previewMode ? "2" : "3"} deleteWidget={deleteWidget} project={projectData} widgets={widgets} setWidgets={setWidgets} previewMode={previewMode} reRender={() => {
                                setRerender(!reRender)
                            }} />
                        }
                    </motion.div>


                </> : <>
                        <Container style={{
                            height:"80vh",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }}>
                            <Card style={{
                                display:"flex",
                                flexDirection:"column",
                                justifyContent:"center",
                                alignItems:"center",
                                padding:"10px",
                                width:"min(900px, 90%)",
                            }}>
                                <img src="https://www.shutterstock.com/image-vector/lock-sign-600nw-79321654.jpg" style={{
                                    maxWidth:"max(300px,90%)",
                                }} alt="" />
                                <h5>
                                    This Project is restricted from public view 
                                </h5>
                            </Card>
                        </Container>


                </>
            }


            <MonitoringFooter />
        </div>
    )
}

export default MonitoringWorkspacePage