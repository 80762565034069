import React, { useContext } from 'react'
import DescriptionEditing from './DescriptionEditing'
import AccessEditing from './AccessEditing'
import { ProjectDataType } from '../../../../types/Dashboard'
import DeleteProject from './DeleteProject'
import { UserContext, userInitialState } from '../../../../context/UserContext'
import BluePrintPublisher from './BluePrintPublisher'

type Props = {
    projectData: ProjectDataType,
    setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    refreshProject: () => void
}

function ProjectSettings({
    projectData,
    setIsSettingsOpen,
    refreshProject
}: Props) {
    const {user} = useContext(UserContext)
    return (
        <div>
            <AccessEditing project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} />
            <DescriptionEditing project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} />
            <DeleteProject project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} />
            {
                user.userData?.id === userInitialState.userData?.id ? <BluePrintPublisher project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} /> : null
            }
        </div>
    )
}

export default ProjectSettings