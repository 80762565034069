import Blockly from "blockly";
import { BlockColors } from "./color-mapping";
import { component_base64 } from "./components_base64";


Blockly.Blocks['delay'] = {
    init: function () {
        this.appendValueInput("delay")
            .setCheck("Number")
            .appendField("delay (sec)");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Time);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['print'] = {
    init: function () {
        this.appendValueInput("print")
            .appendField("Print");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Machine);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['loop_esp'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Loop");
        this.appendStatementInput("NAME")
            .setCheck(null);
        this.setPreviousStatement(true, null);
        this.setColour(BlockColors.Loop);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['set_led'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.LED_PCB, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Set LED");
        this.appendDummyInput()
        this.appendValueInput("pin")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("value")
            .setCheck("Boolean")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['LED']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['pins'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([
                ["D0", "0"],
                ["D1", "1"],
                ["D2", "2"],
                ["D3", "3"],
                ["D4", "4"],
                ["D5", "5"],
                ["D6", "6"],
                ["D7", "7"],
                ["D8", "8"],
                ["D9", "9"],
                ["D10", "10"],
                ["D11", "11"],
                ["D12", "12"],
                ["D13", "13"],
                ["D14", "14"],
                ["D15", "15"],
                ["D16", "16"],
                ["D17", "17"],
                ["D18", "18"],
                ["D19", "19"],
                ["D20", "20"],
                ["D21", "21"],
                ["D22", "22"],
                ["D23", "23"],
                ["D24", "24"],
                ["D25", "25"],
                ["D26", "26"],
                ["D27", "27"],
                ["D28", "28"],
                ["D29", "29"],
                ["D30", "30"],
                ["D31", "31"],
                ["D32", "32"],
                ["D33", "33"],
                ["D34", "34"],
                ["D35", "35"],
                ["D36", "36"],
                ["D37", "37"],
                ["D38", "38"],
                ["D39", "39"],
            ]), "pins");
        this.setOutput(true, "String");
        this.setColour(BlockColors.Constants);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['analog_pins'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([
                ["A32", "32"],
                ["A33", "33"],
                ["A34", "34"],
                ["A35", "35"],
                ["A36", "36"],
                ["A37", "37"],
                ["A38", "38"],
                ["A39", "39"],
            ]), "analog_pins");
        this.setOutput(true, "String");
        this.setColour(BlockColors.Constants);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['tf'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([
                ["TRUE", "1"],
                ["FALSE", "0"]
            ]), "tf");
        this.setOutput(true, "Boolean");
        this.setColour(BlockColors.Constants);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['abs'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck("Number")
            .appendField("absolute");
        this.setInputsInline(true);
        this.setOutput(true, "Number");
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['map'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("map");
        this.appendValueInput("value")
            .setCheck("Number")
            .setAlign(Blockly)
            .appendField("value");
        this.appendDummyInput()
            .appendField("from (");
        this.appendValueInput("fl")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT);
        this.appendDummyInput()
            .appendField(",");
        this.appendValueInput("fh")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT);
        this.appendDummyInput()
            .appendField(") to (");
        this.appendValueInput("tl")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT);
        this.appendDummyInput()
            .appendField(",");
        this.appendValueInput("th")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT);
        this.appendDummyInput()
            .appendField(")");
        this.setInputsInline(true);
        this.setOutput(true, "Number");
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['min'] = {
    init: function () {
        this.appendValueInput("l")
            .setCheck("Number")
            .appendField("min(");
        this.appendDummyInput()
            .appendField(",");
        this.appendValueInput("h")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField(")");
        this.setInputsInline(true);
        this.setOutput(true, "Number");
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['max'] = {
    init: function () {
        this.appendValueInput("l")
            .setCheck("Number")
            .appendField("max(");
        this.appendDummyInput()
            .appendField(",");
        this.appendValueInput("h")
            .setCheck("Number");
        this.appendDummyInput()
            .appendField(")");
        this.setInputsInline(true);
        this.setOutput(true, "Number");
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};






Blockly.Blocks['serial_begin'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Serial Begin")
            .appendField(new Blockly.FieldDropdown([
                ["110", "110"],
                ["300", "300"],
                ["600", "600"],
                ["1200", "1200"],
                ["2400", "2400"],
                ["4800", "4800"],
                ["9600", "9600"],
                ["14400", "14400"],
                ["19200", "19200"],
                ["38400", "38400"],
                ["57600", "57600"],
                ["115200", "115200"],
                ["128000", "128000"],
                ["256000", "256000"]
            ]), "NAME");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['serial_read'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Serial read");
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['serial_print'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Serial print");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([
                ["\\n", "\\n"],
                ["''", "''"]
            ]), "NAME");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['serial_available'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Serial available");
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['read_string'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("read String");
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['parse_int'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Serial parseInt");
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['set_buzzer'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.BUZZER_PCB, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Set Buzzer");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("value")
            .setCheck("Boolean")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Buzzer']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['set_motor'] = {
    init: function () {
        this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(new Blockly.FieldImage(component_base64.DC_MOTOR, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Set Motor");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin1");
        this.appendValueInput("pin2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin2");
        this.appendValueInput("value1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value1");
        this.appendValueInput("value2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value2");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Motor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['set_bo_motor'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.BO_MOTOR, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Set BO Motor");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin1");
        this.appendValueInput("pin2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin2");
        this.appendValueInput("value1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value1");
        this.appendValueInput("value2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value2");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Motor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['traffic_light_define_pins'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("define traffic light pins");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("red")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("red");
        this.appendValueInput("green")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("green");
        this.appendValueInput("blue")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("blue");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['LED']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['traffic_light_set_light'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("set light");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("red")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("red");
        this.appendValueInput("yellow")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("yellow");
        this.appendValueInput("green")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("green");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['LED']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['traffic_light_upgraded'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.RGB_LED, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Set light");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendDummyInput()
            .appendField("Red");
        this.appendValueInput("pin1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("value1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.appendDummyInput()
            .appendField("Green");
        this.appendValueInput("pin2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("value2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.appendDummyInput()
            .appendField("Blue");
        this.appendValueInput("pin3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("value3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['LED']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};






Blockly.Blocks['digital_write'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Digital write");
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("val")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Machine);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['analog_write'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Analog write");
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.appendValueInput("val")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Machine);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['print_digital_input_reading'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Print Digital Input Reading pin");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['touch_sensor_input'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.TOUCH_SENSOR, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Touch Sensor Input");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors["Touch Sensor"]);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['light_sensor_input'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.LDR_PCB, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Light Sensor Input");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors["LDR Sensor"]);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['ir_sensor_input'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.IR_SENSOR, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("IR Sensor Input");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors["IR Sensor"]);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['mq2_digital_input'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.MQ2, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("MQ2 Sensor Digital Input");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Functions']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['mq2_lpg_percentage'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("LPG Gas Percentage");
        this.setOutput(true, null);
        this.setColour(BlockColors['Functions']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['mq2_smoke_percentage'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Smoke Percentage");
        this.setOutput(true, null);
        this.setColour(BlockColors['Functions']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['mq2_hydrogen_percentage'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Hydrogen Gas Percentage");
        this.setOutput(true, null);
        this.setColour(BlockColors['Functions']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['mq2_methane_percentage'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Methane Gas Percentage");
        this.setOutput(true, null);
        this.setColour(BlockColors['Functions']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['mq2_analog_input'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.MQ2, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("MQ2 Sensor Analog Input");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Functions']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['digital_pin_pin'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Digital input pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Machine']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['analog_input_pin'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Analog input pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Machine']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['input_pullup_pin'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Input pullup pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Machine']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['print_analog_input_reading'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Print analog input reading pin");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['pot_value'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Potentiometer Value");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(285);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['joystick_pins'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.JOYSTICK, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Joystick Pins");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("x")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("x");
        this.appendValueInput("y")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("y");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['8x8  Matrix']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['jysX'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("joystick X");
        this.setOutput(true, null);
        this.setColour(BlockColors['8x8  Matrix']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['jysY'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("joystick Y");
        this.setOutput(true, null);
        this.setColour(BlockColors['8x8  Matrix']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};







Blockly.Blocks['define_dht_pin'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.DHT11, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Define DHT")
            .appendField(new Blockly.FieldDropdown([
                ["11", "11"],
                ["22", "22"]
            ]), "dht");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['DHT11 Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['read_humidity'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("read Humidity");
        this.setOutput(true, null);
        this.setColour(BlockColors['DHT11 Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['read_temp_c'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("read Temp (c)");
        this.setOutput(true, null);
        this.setColour(BlockColors['DHT11 Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['read_temp_f'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("read Temp (f)");
        this.setOutput(true, null);
        this.setColour(BlockColors['DHT11 Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['define_ultrasonic_pins'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.ULTRASONIC, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Define Ultrasonic pins");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("trig")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("trig");
        this.appendValueInput("echo")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("echo");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Ultra Sonic Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['dist_cm'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("distance(cm)");
        this.setOutput(true, null);
        this.setColour(BlockColors['Ultra Sonic Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['keypad_row_pins'] = {
    init: function () {

        this.appendDummyInput()
            .appendField("Keypad row pins");
        this.appendValueInput("p1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 1");
        this.appendValueInput("p2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 2");
        this.appendValueInput("p3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 3");
        this.appendValueInput("p4")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 4");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Keypad']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['keypad_col_pins'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Keypad column pins");
        this.appendValueInput("p1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 1");
        this.appendValueInput("p2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 2");
        this.appendValueInput("p3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 3");
        this.appendValueInput("p4")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin 4");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Keypad']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['initialize_keypad'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.KEYPAD, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize Keypad");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Keypad']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['readkey'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("read Key");
        this.setOutput(true, null);
        this.setColour(BlockColors['Keypad']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['key_press_detected_in'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("key press detected in");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Keypad']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['moisture_sensor_input'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.MOISTURE_SENSOR, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Moisture Sensor Input");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(BlockColors['Buzzer']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['pump_motor'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Pump Motor");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin1");
        this.appendValueInput("pin2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin2");
        this.appendValueInput("value1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value1");
        this.appendValueInput("value2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value2");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Motor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};










Blockly.Blocks['init_i2c_lcd'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.LCD, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize I2C LCD");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("addr#")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("addr#");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.LCD);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['i2c_clear_lcd_display'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("I2C LCD clear display");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.LCD);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['i2c_lcd_print'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("I2C LCD Print");
        this.appendValueInput("r")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("row");
        this.appendValueInput("c")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("column");
        this.appendValueInput("t")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("text");
        this.setInputsInline(false);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.LCD);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['eight_segment_display_define'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Define pins 4 pin seven segment display");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("clk")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("clk");
        this.appendValueInput("dio")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("dio");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Variables']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['show_on_7_segment_display'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Show on 7 segment display");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("text")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("text");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Variables']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['i2c_lcd_brightness_level'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Display number");
        this.appendValueInput("number")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("number");
        this.appendValueInput("colon")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("colon");
        this.appendValueInput("lead_0")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("lead_0");
        this.setInputsInline(false);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['dotmatrix_pins'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.DOTMATRIX8x8, 100, 90), "image")
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Dotmatrix Pins");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("din")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("din");
        this.appendValueInput("cs")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("cs");
        this.appendValueInput("clk")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("clk");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(285);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['dotmatrix_display'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Display");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("ROW1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW1");
        this.appendValueInput("ROW2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW2");
        this.appendValueInput("ROW3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW3");
        this.appendValueInput("ROW4")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW4");
        this.appendValueInput("ROW5")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW5");
        this.appendValueInput("ROW6")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW6");
        this.appendValueInput("ROW7")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW7");
        this.appendValueInput("ROW8")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("ROW8");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(285);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['i2c_lcd_set_row'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Set row");
        this.appendValueInput("r")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("row");
        this.appendValueInput("v")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['i2c_lcd_set_col'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Set column");
        this.appendValueInput("c")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("column");
        this.appendValueInput("v")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['i2c_lcd_set_pixel'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Set pixel");
        this.appendValueInput("r")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("row");
        this.appendValueInput("c")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("column");
        this.appendValueInput("v")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};










Blockly.Blocks['init_rfid'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.RFID, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize RFID");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("SDA")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("SDA");
        this.appendValueInput("RST")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("RST");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['RFID Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['card_present'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Card present");
        this.setOutput(true, null);
        this.setColour(BlockColors['RFID Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['read_rfid_card'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Read RFID Card");
        this.setOutput(true, null);
        this.setColour(BlockColors['RFID Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['print_id'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Print ID");
        this.setOutput(true, null);
        this.setColour(BlockColors['RFID Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['id_number'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("ID Number");
        this.setOutput(true, null);
        this.setColour(BlockColors['RFID Sensor']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['initialize_accelerometer'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.ACCELEROMETER, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize Accelerometer");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(285);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['read_xyz_values'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Read x,y,z values");
        this.appendValueInput("x")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("x");
        this.appendValueInput("y")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("y");
        this.appendValueInput("z")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("z");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(285);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['remote_receiver'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("IR Remote Receiver");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(285);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['remote_button_pressed'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Remote button pressed");
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['button_code'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Button code");
        this.setOutput(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['serial_print_button_value'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Serial Print Button Value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(230);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['init_colour_sensor'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.COLOR_SENSOR, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize colour sensor");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("s0")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("s0");
        this.appendValueInput("s1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("s1");
        this.appendValueInput("s2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("s2");
        this.appendValueInput("s3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("s3");
        this.appendValueInput("out")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("out");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['IOT']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['activate_red_mode'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Activate Red Mode");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['IOT']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['activate_green_mode'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Activate Green Mode");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['IOT']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['activate_blue_mode'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Activate Blue Mode");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['IOT']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['read_colour_intensity'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Read Colour Intensity");
        this.setOutput(true, null);
        this.setColour(BlockColors['IOT']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};




Blockly.Blocks['initialize_bt'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Initialize Bluetooth");
        this.appendValueInput("tx")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("TX");
        this.appendValueInput("rx")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("RX");
        this.appendValueInput("baud")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Baud rate");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Bluetooth']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['bt_available'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Bluetooth available");
        this.setOutput(true, null);
        this.setColour(BlockColors['Bluetooth']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['bt_read'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Bluetooth read");
        this.setOutput(true, null);
        this.setColour(BlockColors['Bluetooth']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['bt_read_str'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("read String from BT");
        this.setOutput(true, null);
        this.setColour(BlockColors['Bluetooth']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['bt_print'] = {
    init: function () {
        this.appendValueInput("NAME")
            .setCheck(null)
            .appendField("Bluetooth print");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([
                ["\\n", "\\n"],
                ["''", "''"]
            ]), "NAME");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Bluetooth']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['end_of_print'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("End of print");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Bluetooth']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['initialize_wifi'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Initialize WiFi");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['text-block'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Text")
            .appendField(new Blockly.FieldTextInput(""), "text");
        this.setInputsInline(true);
        this.setOutput(true, "String");
        this.setColour(BlockColors.Constants);
        this.setTooltip("Enter any required text");
        this.setHelpUrl("");
    }
}

Blockly.Blocks['set_wifi_credentials'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Set WiFi Credentials");
        this.appendValueInput("ssid")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("WiFi Name");
        this.appendValueInput("password")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Wifi Password");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['connect_to_wifi'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Connect to WiFi");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['is_wifi_connected'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("is wifi connected");
        this.setOutput(true, "Boolean");
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['start_server'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Start Server");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['get_ip_address'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("ip_address");
        this.setOutput(true, "String");
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['start_server'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(" Start Server");

        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['handle_request'] = {
    init: function () {
        this.appendDummyInput()
            .appendField("Start Handling Server");
        this.appendStatementInput('DO').appendField('')
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['is_request_equals_to'] = {
    init: function () {
        this.appendValueInput("request_value")
            .setCheck(null)
            .appendField("Request equals to");
        this.setInputsInline(true);

        this.setOutput(true, "Boolean");
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['call_function'] = {
    init: function () {
        this.appendValueInput("call")
            .setCheck(null)
            .appendField("Call");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Functions);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





Blockly.Blocks['8-pin-seven-segment'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("8-pin seven segment");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.appendValueInput("pin1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin1");
        this.appendValueInput("value1")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value1");

        this.appendValueInput("pin2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin2");
        this.appendValueInput("value2")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value2");

        this.appendValueInput("pin3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin3");
        this.appendValueInput("value3")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value3");
        this.appendValueInput("pin4")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin4");
        this.appendValueInput("value4")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value4");
        this.appendValueInput("pin5")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin5");
        this.appendValueInput("value5")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value5");
        this.appendValueInput("pin6")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin6");
        this.appendValueInput("value6")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value6");
        this.appendValueInput("pin7")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("pin7");
        this.appendValueInput("value7")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("value7");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Variables']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};





// servo blocks

Blockly.Blocks['servo'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.SERVO, 100, 90), "image")

        this.appendDummyInput()
            .appendField("Servo");
        this.appendValueInput("pin")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Pin");

        this.appendValueInput("degree")
            .setCheck("Number")
            .appendField("Move Servo (in degrees)");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Servo']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['move_servo'] = {
    init: function () {
        this.appendValueInput("degree")
            .setCheck("Number")
            .appendField("Move Servo (in degrees)");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['Servo']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

// 8x8 matrix 

Blockly.Blocks['8x8_matrix'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.DOTMATRIX8x8, 100, 90), "image")

        this.appendDummyInput()
            .appendField("8x8 Matrix");
        this.appendValueInput("number_of_pins")
            .appendField("Initialize-Matrix");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['8x8  Matrix']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.Blocks['print_on_matrix'] = {
    init: function () {
        this.appendValueInput("column")
            .appendField("column value");
        this.appendValueInput("value")
            .appendField("Print on Matrix");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors['8x8  Matrix']);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

// IOT Blocks

Blockly.Blocks['init_i2c_OLED'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.OLED, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize I2C OLED");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.LCD);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['init_i2c_adxl345'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.ACCELEROMETER, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Initialize I2C Accelerometer");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['adxl345_result'] = {
    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.ACCELEROMETER, 100, 90), "image")

        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField("Accelerometer Values");
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.WiFi);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};


Blockly.Blocks['i2c_OLED_print'] = {

    init: function () {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(new Blockly.FieldImage(component_base64.OLED, 100, 90), "image")
        this.appendDummyInput()
            .appendField("I2C OLED Print");
        this.appendValueInput("r")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("row");

        this.appendValueInput("c")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("column");
        this.appendValueInput("t")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("text");
        this.setInputsInline(false);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.LCD);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};



Blockly.Blocks['initialize_IOT'] = {

    init: function () {
        this.appendDummyInput()
            .appendField("Initialize IOT");
        this.appendValueInput("api_key")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Account API Key");
        this.appendValueInput("project_secret_key")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("Project Secret Key");

        this.appendValueInput("ssid")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("WiFi Name");
        this.appendValueInput("password")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("WiFi Password");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.IOT);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['send_data_iot'] = {

    init: function () {
        this.appendDummyInput()
            .appendField("Send Data");
        this.appendValueInput("name")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("IOT Variable Name");
        this.appendValueInput("value")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(" Value");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.IOT);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['get_data_iot'] = {

    init: function () {
        this.appendDummyInput()
            .appendField("Get Data");
        this.appendValueInput("name")
            .setCheck(null)
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("IOT Variable Name");
        this.setOutput(true, "String");
        this.setColour(BlockColors.IOT);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

export default Blockly