import { ServicesConfigs } from "../../config.environment";



export const s3Config = {
    bucketName:  ServicesConfigs.storage.aws.S3_BUCKET_NAME,
    dirName: "IOT/blueprints",    
    region: ServicesConfigs.storage.aws.AWS_REGION,
    accessKeyId: ServicesConfigs.storage.aws.AWS_ACCESS_ID,
    secretAccessKey: ServicesConfigs.storage.aws.AWS_SECRET_ACCESS_ID,
}