import React, { useContext, useState } from 'react'
import { ProjectDataType } from '../../../../types/Dashboard'
import { Button, Switch, Typography } from '@mui/material'
import { UserContext } from '../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext'
import { BackendConfigs } from '../../../../config.environment'
import { APIResponse } from '../../../../types/Response'
import { toast } from 'react-toastify'
import { ContentDialogBox } from '../../../../components/dialog_box'
import { useNavigate } from 'react-router-dom'

type Props = {
    project: ProjectDataType['project'],
    setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    refreshProject: () => void
}

function DeleteProject({ project }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [deleteDialogBox, setDeleteDialogBox] = React.useState(false)
    const navigate = useNavigate()
    async function deleteProject() {
        handleGlobalLoading(true, "Deleting Project")
        try {
            const response = await fetch(`${BackendConfigs.url}/DeleteProjectAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: project._id,
                    ownerId: user.userData?.id
                })
            })
            const result = await response.json() as APIResponse
            if (response.status === 200) {
                toast.success("Project Deleted Successfully")
                navigate("/dashboard/projects")
                setDeleteDialogBox(false)
            } else {
                toast.error(result.message)
            }


        } catch (error) {
            console.log(error);
            toast.error("Project Deletion Failed")
        }
    }

    return (
        <div style={{
            width: "95%",
            margin: "10px auto",
        }}>
            <Typography sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                margin: "10px",
            }}>
                <Button color='error' variant='outlined' onClick={() => {
                    setDeleteDialogBox(true)
                }}>
                    Delete Project
                </Button>
            </Typography>
            <p style={{
                fontSize: "12px",
            }}>All Content related to this project will be deleted</p>
            <ContentDialogBox
                isOpen={deleteDialogBox}
                onClose={() => {
                    setDeleteDialogBox(false)
                }}
                title={"Delete Project: " + project.name}
                content={<>
                    <p style={{
                        padding: "0 32px",
                    }}>
                        Are you sure you want to delete this project?
                    </p>
                </>}
                actions={
                    <>
                        <Button variant='contained' size='small' sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px"
                        }}
                            color='error'
                            onClick={() => {
                                deleteProject()
                            }}
                        >
                            Delete
                        </Button>
                    </>
                }
            />
        </div>
    )
}

export default DeleteProject