export const routes={
    ROOT:'/',
    DASHBOARD:{
        ROOT:"/dashboard/main",
        PROJECTS:"/dashboard/projects",
        PROJECT:{
            ROOT:"/dashboard/project",
            PROJECT_BY_ID:"/dashboard/project/:projectId",
            MONITORING_WORKSPACE_FOR_APP:(id:string)=>`/dashboard/project/${id}/monitoring-workspace-application`,
            MONITORING_WORKSPACE:(id:string)=>`/dashboard/project/${id}/monitoring-workspace`,
            CIRCUITRY_WORKSPACE:(id:string)=>`/dashboard/project/${id}/circuitry-workspace`,
        },
        BLUEPRINTS:"/dashboard/sample-project",
        BLUEPRINT:{
            ROOT:"/dashboard/sample-project",
            BLUEPRINT_BY_ID:"/dashboard/sample-project/:projectId",
            MONITORING_WORKSPACE:"/dashboard/sample-project/:projectId/monitoring-workspace",
            CIRCUITRY_WORKSPACE:"/dashboard/sample-project/:projectId/circuitry-workspace",
        },
        SETTINGS:"/dashboard/settings",
    },
    SHARED_MONITORING:{
        ROOT:"/shared-monitor/",
        MONITOR_BY_ID:"/shared-monitor/:projectId",
    },
    CIRCUIT_DESIGNER:"/circuit-designer",
    LOGIC_GATE_SIMULATOR:"/logic-gate-simulator",
    MICROPYTHON_EDITOR:"/micropython-editor",
    NOTFOUND:'/404',
    WILDCARD:"*",
}