import React, { useContext, useState } from 'react'
import { ProjectDataType } from '../../../../types/Dashboard'
import { Button, Select, Typography, MenuItem } from '@mui/material'
import { ContentDialogBox } from '../../../../components/dialog_box'
import ConstructionIcon from '@mui/icons-material/Construction';
import { FileUploader } from "react-drag-drop-files";
import { SettingContext } from '../../../../context/SettingsContext';
import { uploadFile } from '../../../../services/S3Storage/Upload';
import { BackendConfigs } from '../../../../config.environment';
import { UserContext } from '../../../../context/UserContext';
import { toast } from 'react-toastify';
import { APIResponse } from '../../../../types/Response';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
type Props = {
    project: ProjectDataType['project'],
    setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    refreshProject: () => void
}
const fileTypes = ["JPG", "PNG", "GIF"];

function BluePrintPublisher({ project, setIsSettingsOpen, refreshProject }: Props) {
    const [isBluePrintDialogOpen, setIsBluePrintDialogOpen] = React.useState(false)
    const [image, setImage] = useState<string>(project.blueprintImage);
    const [BlueprintType, setBlueprintType] = useState<string>(project.blueprintType || "Activity")
    const [file, setFile] = useState<File | null>(null);
    const [isImageWindowOpen, setIsImageWindowOpen] = React.useState(false)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const { user } = useContext(UserContext)
    const handleChange = async (file: File) => {
        handleGlobalLoading(true, "Uploading Image")
        const extension = file.name.split(".").pop();
        const newName = `${project._id}.${extension}`;
        console.log(file, extension, newName);
        setFile(file);
        if (!!file) {

            try {

                const response = await uploadFile(file, newName) as {
                    location: string,
                    bucket: string,
                    key: string
                };
                console.log(response);
                if (!!response.location) {
                    setImage(response.location)
                    setIsImageWindowOpen(false)
                    toast.info("Image uploaded successfully")
                }
            } catch (error) {

            }
        }
        handleGlobalLoading(false, "Uploading Image")
    };

    async function publishBlueprint() {
        handleGlobalLoading(true, "Publishing Blueprint...")
        try {
            const response = await fetch(`${BackendConfigs.url}/MakeProjectAsBlueprintAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: project._id,
                    ownerId: user.userData?.id,
                    blueprintImage: image,
                    blueprintType: BlueprintType
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success("Blueprint published successfully")
                refreshProject()
                setIsSettingsOpen(false)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    async function removerBlueprint() {
        handleGlobalLoading(true, "Publishing Blueprint...")
        try {
            const response = await fetch(`${BackendConfigs.url}/RemoveFromBlueprintAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: project._id,
                    ownerId: user.userData?.id,
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success("Blueprint removed successfully")
                refreshProject()
                setIsSettingsOpen(false)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }

    if (project.isCreatedFromBlueprint) return null
    return (
        <div style={{
            width: "95%",
            margin: "10px auto",
        }}>
            <Typography sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                margin: "10px",
            }}>
                {
                    project.isItBluePrint &&
                    <Button endIcon={<HighlightOffIcon />} variant="contained" color='error' onClick={() => removerBlueprint()} style={{
                        textTransform: "none",
                        marginRight: "10px"
                    }}>Remove Publish</Button>
                }
                <Button endIcon={<ConstructionIcon />} variant="contained" onClick={() => setIsBluePrintDialogOpen(true)} style={{
                    textTransform: "none",
                }}>{project.isItBluePrint ? "Update Blueprint" : "Publish Blueprint"}</Button>
            </Typography>
            <ContentDialogBox
                isOpen={isBluePrintDialogOpen}
                onClose={() => setIsBluePrintDialogOpen(false)}
                title="Publish as Blueprint"
                actions={
                    <>
                        <Button variant='outlined' color='primary' sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px",
                        }} onClick={() => {
                            setIsImageWindowOpen(!isImageWindowOpen)
                        }}>
                            {
                                isImageWindowOpen ? "Close Upload Window" : "Upload thumbnail"
                            }

                        </Button>
                        <Button variant='contained' color='success' sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px",
                        }} onClick={() => {
                            publishBlueprint()
                        }}>
                            {
                                project.isItBluePrint ? "Update Blueprint" : "Publish Blueprint"
                            }
                        </Button>
                    </>
                }
                content={
                    <>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>

                            <div style={{
                                height: "200px",
                                margin: "10px 0",
                                width: "350px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}>
                                <h6 style={{
                                    width: "100%"
                                }}>Thumbnail</h6>
                                {
                                    isImageWindowOpen ?

                                        <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} style={{ height: "200px" }} classes="file_uploader" /> : <img src={image} alt="" style={{
                                            maxWidth: "200px",
                                        }} />
                                }
                            </div>

                            <div style={{
                                width: "350px",
                                margin: "10px auto",
                            }}>
                                <h6>Blueprint Type</h6>
                                <Select size='small' label="Blueprint Type" fullWidth value={BlueprintType} onChange={(e) => {
                                    setBlueprintType(e.target.value)
                                }} placeholder='Select Blueprint Type'>

                                    <MenuItem value={"Activity"}>{"Activity"}</MenuItem>
                                    <MenuItem value={"Project"}>{"Project"}</MenuItem>

                                </Select>

                            </div>





                        </div>
                    </>
                }
            />
        </div>
    )
}

export default BluePrintPublisher