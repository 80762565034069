import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme, getRandomValueForEducobotColors, SettingContext } from '../../context/SettingsContext'
import { BackendConfigs } from '../../config.environment'
import { UserContext } from '../../context/UserContext'
import GraphComponent from '../../components/graph'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../routes/routes'
import { APIResponse } from '../../types/Response'
import { formatDistance, set } from 'date-fns'
import MapComponent from '../../components/map-component'

type Props = {}

function Dashboard({ }: Props) {
    const { user } = useContext(UserContext)
    const [statistics, setStatistics] = useState<{
        "projectsCount": 0,
        "sensorsCount": 0,
        "totalHitCount": 0,
        "variablesCount": 0,
        recentProjects: [],
        recentHits: {
            name: string,
            variableName: string,
            variableValue: string,
            createdAt: string,
            projectName: string
        }[],
    }>({
        "projectsCount": 0,
        "sensorsCount": 0,
        "totalHitCount": 0,
        "variablesCount": 0,
        recentProjects: [],
        recentHits: [],
    })
    const { settings, handleGlobalLoading } = useContext(SettingContext)

    async function getStatistics() {
        if (user.isLogin) {

            try {
                const response = await fetch(`${BackendConfigs.url}/GetDashboardStatisticsAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    setStatistics(
                        {
                            "projectsCount": result.data.projectCount,
                            "sensorsCount": result.data.sensorsCount,
                            "totalHitCount": result.data.sensorHitCount,
                            "variablesCount": result.data.variableCount,
                            recentProjects: result.data.projects,
                            recentHits: result.data.sensorsData.map((value: any) => {
                                return {
                                    ...value,
                                    projectName: result.data.projects.filter((project: any) => {
                                        return project._id === value.projectId
                                    })[0].name
                                }
                            }),
                        }
                    )
                }
            } catch (error) {
                console.log(error);
            }
        }


        handleGlobalLoading(false)
    }



    useEffect(() => {
        handleGlobalLoading(true, "Fetching Statistics")
        getStatistics()
        const interval = setInterval(() => {
            getStatistics()
        }, 3000)
        return () => clearInterval(interval)
    }, [user])
    return (
        <div>

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "80vh",
                margin: "20px 0",
                gap: "5px",
            }}>


                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} md={12}>
                        <div style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                            gap: "10px",
                            padding: "10px",
                        }}>
                            <CountComponent title="Projects" count={statistics.projectsCount} color={eduCOBOTColorScheme.blue} />
                            <CountComponent title="Sensors" count={statistics.sensorsCount} color={eduCOBOTColorScheme.orange} />
                            <CountComponent title="Variables" count={statistics.variablesCount} color={eduCOBOTColorScheme.green} />
                            <CountComponent title="Hits" count={statistics.totalHitCount} color={eduCOBOTColorScheme.gray} />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <div style={{
                            minHeight: "450px",
                            maxHeight: "400px",
                        }}>
                            <h6>Recent Projects</h6>
                            <div style={{
                                height: "400px",
                                overflowY: "scroll",
                                border: "1px solid #00000022",
                                backdropFilter: "blur(5px)",
                            }}>
                                {
                                    statistics.recentProjects?.length === 0 && <p style={{ textAlign: "center", margin: "10px" }}>No Recent Projects</p>
                                }
                                {
                                    statistics.recentProjects?.map((project, index) => <RecentProjectCard project={project} key={index} />)
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} border={0}>
                        <div style={{
                            minHeight: "450px",
                            maxHeight: "400px",
                        }}>
                            <h6>Recent Hits</h6>
                            <div style={{
                                height: "400px",
                                overflowY: "scroll",
                                border: "1px solid #00000022",
                                backdropFilter: "blur(5px)",
                            }}>
                                {
                                    statistics.recentHits?.length === 0 && <p style={{ textAlign: "center", margin: "10px" }}>No Recent Hits</p>
                                }
                                {
                                    statistics.recentHits?.map((hit, index) => <RecentProjectHitCard hit={hit} key={index} />)
                                }
                            </div>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} md={12} border={0}>
                        {
                            statistics.recentHits?.length === 0 && <p style={{ textAlign: "center", margin: "10px" }}>No Recent Hits</p>
                        }
                        <DashboardGraph key={`${statistics.projectsCount}`} hits={statistics.recentHits} />
                    </Grid>
                    <Grid item xs={12} md={12} border={0}>

                        <MapComponent coordinates={[]}/>












                    </Grid>
                </Grid>










            </div>




        </div>
    )
}

export function DashboardGraph({
    hits
}: {
    hits: {
        name: string,
        variableName: string,
        variableValue: string,
        createdAt: string,
        projectName: string
    }[]
}) {


    const [labels, setLabels] = useState<string[]>([])
    const [data, setData] = useState<number[]>([])
    function handleData() {
        console.log(hits);

        const seconds = hits.map((hit) => {
            const time = (new Date(parseInt(hit.createdAt))).toLocaleTimeString()
            return time.slice(0, -6) + " " + time.slice(-2)
        })
        // console.log(seconds);
        let values = {} as {
            [key: string]: number
        }
        seconds.forEach(element => {
            values[element] = element in values ? values[element] + 1 : 1
        });
        console.log(values);
        setLabels(Object.keys(values).reverse())
        setData(Object.values(values).reverse())
    }
    useEffect(() => {
        handleData()
    }, [])


    return <GraphComponent data={data} yLabel='Hits Per Minute' xLabel='Time' valueType=' Hits' graphType='line' labels={labels} height={350} />

}



export function RecentProjectCard({
    project
}: {
    project: {
        name: string,
        lastUpdatedAt: string,
        _id: string,
    }
}) {

    const navigate = useNavigate()







    return (
        <Card sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 5px",
        }}>
            <h6>{project.name}</h6>
            <p style={{
                fontSize: "12px",
                width: "50%",
            }}>

                updated {formatDistance(new Date(parseInt(project.lastUpdatedAt)), new Date(), { addSuffix: true })}
            </p>
            <Button onClick={() => {
                navigate(routes.DASHBOARD.PROJECT.ROOT + "/" + project._id)
            }} size='small' variant='outlined' sx={{
                fontSize: "10px",
            }}>
                View
            </Button>
        </Card>
    )
}

export function RecentProjectHitCard({
    hit
}: {
    hit: {
        name: string,
        variableName: string,
        variableValue: string,
        createdAt: string,
        projectName: string
    }
}) {
    return (
        <Card sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 5px",
        }}>
            <h6 style={{
                fontSize: "12px",
            }}>{hit.projectName}</h6>
            <p style={{
                fontSize: "10px",
                width: "50%",
            }}>
                {
                    (new Date(parseInt(hit.createdAt))).toUTCString()
                }
            </p>
            <div>
                <p style={{
                    fontSize: "9px",
                }}>{hit.variableName}: {hit.variableValue}</p>
            </div>
        </Card>
    )
}


export function CountComponent({
    color,
    count,
    title
}: {
    count: number,
    color: string,
    title: string
}) {
    return (
        <Card style={{
            width: "250px",
            height: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "bolder",
            flexDirection: "column",
            background: color,
            borderRadius: "20px",
        }}
            sx={{
                ":hover": {
                    background: "#f3f3f3"
                }
            }}
        >
            <h3 style={{
                fontSize: "53px",
                color: eduCOBOTColorScheme.white
            }}>{count}</h3>
            <span style={{
                fontSize: "12px",
                color: eduCOBOTColorScheme.white
            }}>
                {title}
            </span>
        </Card>
    )
}



export default Dashboard